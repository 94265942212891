@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
}

#root {
  height: 100%;
}

#root .ant-layout {
  height: 100%;
}

h4 {
  margin-bottom: 0px;
  font-size: 20px;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav:before,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav:before {
  border-bottom: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-input-number.ant-input-number-in-form-item.ant-input-number-status-success {
  width: 100%;
}

.custom-layout-content {
  position: relative;
  padding: 16px;
}

.background-content-layout {
  background-color: white;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.ant-form-item-control-input-content input {
  width: 100%;
  height: 34px;
  padding-left: 12px;
}

s .text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.custom-placeholder-select .ant-select-selection-placeholder {
  color: black;
  opacity: 1;
}

/* Số lượng dòng cho phép  */
.number-of-line-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* custom scroll  */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eee;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

.list_container {
  overflow: auto;
  height: 50px;
  width: 40px;
}

/* custom form  */
.custom-form-item .ant-form-item {
  margin-bottom: 0;
}

.custom-form-item {
  margin-bottom: 0;
}

.custom-submenu-scroll .ant-menu-sub {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

@keyframes opacityChange {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.item-hover {
  opacity: 0;
}

.item-hover:hover {
  opacity: 1;
  cursor: pointer;
  animation: opacityChange 0.5s both;
}

.bold-text-status-false {
  font-weight: bold;
}

.css-color-link {
  color: #597ef7;
}

.ant-form-item-label>label {
  font-weight: 500;
}

.ant-picker {
  height: 34px;
}

.ant-switch-checked {
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.ant-switch-checked span.ant-switch-inner {
  color: #00d053;
}

span.ant-switch-inner {
  color: rgba(0, 0, 0, 0.3);
}

.ant-switch {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: 400;
}

.ant-switch-handle:before {
  background-color: rgba(0, 0, 0, 0.3);
}

.ant-switch-checked .ant-switch-handle:before {
  background-color: #00d053;
  top: -1px;
  right: 0px;
  bottom: 2px;
  left: 0px;
}

.ant-upload-select {
  width: 100%;
}

.ant-tabs-nav-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.table-row-open {
  background-color: #ffffff;
}

.table-row-close {
  background-color: #FFF1F0;
  border-bottom: 1px solid "#ccc";
}

.active-color {
  color: green;
}

.lock-color {
  color: red;
}